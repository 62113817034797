import cx from "classnames";
import React, { useState } from "react";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import useMixpanel from "../../../../hooks/useMixpanel";
import { isValidEmail } from "../../../../utils/email";
import * as styles from "./CollectiveModal.module.scss";

const CollectiveModal = ({isOpen, closeModal}) => {
  const trackEvent = useMixpanel();
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  }
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    if(!isValidEmail(email)) {
      setError("Please enter a valid email.");
      return;
    }
    try {
      const response = await fetch('/email-submissions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
        },
        body: JSON.stringify({
          email_submission: {
            email,
            site: "Collective",
          }
        })
      });
      const json = await response.json();
      if(!response.ok) {
        if(json.error) {
          setError(json.message[0]);
        } else {
          throw new Error("Failed to submit data", response);
        }
      } else {
        setHasSubmitted(true);
        trackEvent("Submitted Email", { "Email": email })
      }
    } catch (error) {
      console.error("ERROR:", error);
      setError("Oops! Something went wrong. Please try again later.");
    }
  }
  
  return (
    <Modal 
      open={isOpen} 
      onClose={closeModal} 
      center
      classNames={{
        root: "modal-root",
        overlay: cx("modal-overlay", styles.overlay),
        modal: cx("modal", styles.modal),
        closeIcon: styles.closeIcon
      }}
    >
      <div className={cx("modal-content")}>
        {
          !hasSubmitted && <>
            <h2 className={cx("title", styles.title)}>Get on the list</h2>
            <p>We're opening more membership slots soon! Enter your email below to be the first to know when new memberships are available.</p>
            <form onSubmit={handleSubmit}>
              <input type="text" placeholder="Enter your email" value={email} onChange={handleEmailChange} />
              <input type="submit" value="Submit"/>
            </form>
            {error && <p className={cx("error", styles.error)}>{error}</p>}
          </>
        }
        {
          hasSubmitted && <>
            <h2 className={cx("title", styles.title)}>Thanks!</h2>
            <button className={cx("button", styles.button)} onClick={closeModal}>Close</button>
          </>
        }
      </div>
    </Modal>
  )
}

export default CollectiveModal;