import cx from 'classnames';
import React from 'react';
import Logo from "../../../../assets/images/collective/collective-logo.svg";
import Hero1 from "../../../../assets/images/collective/hero-1.png";
import Hero2 from "../../../../assets/images/collective/hero-2.png";
import Hero3 from "../../../../assets/images/collective/hero-3.png";
import Image1 from "../../../../assets/images/collective/image-1.png";
import Image2 from "../../../../assets/images/collective/image-2.png";
import Image3 from "../../../../assets/images/collective/image-3.png";
import Image4 from "../../../../assets/images/collective/image-4.png";
import useMixpanel from '../../../hooks/useMixpanel';
import useModal from '../../../hooks/useModal';
import * as styles from './Collective.module.scss';
import CollectiveModal from './CollectiveModal';

const Collective = () => {
  const { isOpen, openModal, closeModal } = useModal();
  const trackEvent = useMixpanel();

  const handleClick = (e) => {
    e.preventDefault();
    trackEvent("Clicked Join Now");
    openModal();
  }

  return (
    <div className={cx("container", styles.container)}>
      <CollectiveModal isOpen={isOpen} closeModal={closeModal} />
      <div className={styles.headerContainer}>
        <div className={styles.header}>
          <div className={styles.logoContainer}>
            <img src={Logo} />
          </div>
          <div className={styles.line} />
        </div>
      </div>
      <section className={styles.hero}>
        <div className={styles.heroContent}>
          <div className={cx(styles.heroImgContainer, styles.heroImgContainer1)}>
            <img src={Hero1} alt="freelancer"/>
          </div>
          <div className={styles.heroTitleContainer}>
            <h1 className={styles.heroTitle}>
              The 
              <span>Freelancer Support</span> You've Been Looking For.
            </h1>
          </div>
          <p className={styles.heroDescription}>
            Join a community of freelancers accessing exclusive resources, one-on-one guidance, and member-only perks. 
          </p>
          <div className={cx(styles.heroImgContainer, styles.heroImgContainer2)}>
            <img src={Hero2} alt="freelancer"/>
          </div>
          <div className={cx(styles.heroImgContainer, styles.heroImgContainer3)}>
            <img src={Hero3} alt="freelancer"/>
          </div>
        </div>
      </section>
      <section className={cx("section-container")}>
        <div className={cx("section", "section--reverse", styles.section)}>
          <div className={styles.sectionImgContainer}>
            <img src={Image1} />
          </div>
          <div className={styles.sectionTextContainer}>
            <h3 className={styles.sectionTitle}>
              Join a community of people who “get it.”
            </h3>
            <p>The Collective was created to provide support tailored to freelancers. Join a community that understands your journey - the ups, the downs, and everything in between.</p>
            <button onClick={handleClick}>Join Now</button>
          </div>
        </div>
      </section>
      <section className={cx("section-container", styles.sectionContainerMid, styles.sectionContainerLarge)}>
        <div className={cx("section", styles.section)}>
          <div className={styles.sectionImgContainer}>
            <img src={Image2} />
          </div>
          <div className={styles.sectionTextContainer}>
            <h3 className={styles.sectionTitle}>
              GET FINANCIAL GUIDANCE ON RETIREMENT PLANNING, 
              TAX PLANNING, AND
              SELECTING INSURANCE PRODUCTS FROM EXPERTS WHO KNOW FREELANCERS INSIDE AND OUT.
            </h3>
            <p>Freelancers deserve advice that fits. As a member you’ll get on-demand access to expert guidance tailored just for you. Get the personalized support you need to plan, manage and thrive.</p>
          </div>
        </div>
      </section>
      <section className={cx("section-container")}>
        <div className={cx("section", "section--reverse", styles.section)}>
          <div className={styles.sectionImgContainer}>
            <img src={Image3} />
          </div>
          <div className={styles.sectionTextContainer}>
            <h3 className={styles.sectionTitle}>
              Access perks usually reserved for corporate employees.
            </h3>
            <p>Get exclusive pricing, discounts, and special offers from our network of top brands, including airlines and business tools. Elevate your freelance lifestyle and save on everyday essentials.</p>
          </div>
        </div>
      </section>
      <section className={cx("section-container", styles.sectionContainerLast, styles.sectionContainerLarge)}>
        <div className={cx("section", styles.section)}>
          <div className={styles.sectionImgContainer}>
            <img src={Image4} />
          </div>
          <div className={styles.sectionTextContainer}>
            <h3 className={styles.sectionTitle}>
              Member-only events.
            </h3>
            <p>Dive into live and virtual experiences, workshops, and expert-led sessions.</p>
            <button onClick={handleClick}>Join Now</button>
          </div>
        </div>
      </section>
      <section className={styles.sectionFooter}>
        <h4>A membership designed just for you.</h4>
      </section>
      <footer className={styles.footer}>
        2024 The Collective
      </footer>
    </div>
  )
}

export default Collective;